<script>
export default {
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue'),
    componentSearchSection: () => import('./sections/SearchSection.vue')
  },
  data: () => ({})
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentSearchSection />
  </div>
</template>
