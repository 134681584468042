<script>
import View from '@/views/View'

export default {
  name: 'TrainingPage',
  metaInfo: { title: 'Certification' },
  extends: View,
  components: {
    componentTrainingSection: () => import('./sections/TrainingSection.vue')
  }
}
</script>

<template>
  <div>
    <componentTrainingSection />
  </div>
</template>
