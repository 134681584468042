// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#42A5F6',
        secondary: '#050B1F',
        accent: '#204165',
        lightbluedarken1: '#039BE5',
        lightbluedarken2: '#0288D1',
        lightbluedarken3: '#0277BD',
        lightbluedarken4: '#01579B',
        bluegrey: '#607D8B',
        bluegreylighten1: '#78909C',
        bluegreylighten2: '#90A4AE',
        bluegreylighten3: '#B0BEC5',
        bluegreylighten4: '#CFD8DC',
        bluegreylighten5: '#ECEFF1',
        bluegreydarken1: '#546E7A',
        bluegreydarken2: '#455A64'
      },
      dark: {
        primary: '#50778D',
        secondary: '#0B1C3D',
        accent: '#204165'
      }
    }
  }
})
