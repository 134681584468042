<script>
import View from '@/views/View'

export default {
  name: 'NetworkPage',
  metaInfo: { title: 'Active Sepcialists' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue'),
    componentNetworkSection: () => import('./sections/NetworkSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentNetworkSection />
  </div>
</template>
