// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '', // url path (none)
          name: 'homeroute', // route name
          component: () => import('@/views/LandingHome.vue') // source code
        },
        {
          path: 'eula',
          name: 'eularoute',
          component: () => import('@/views/EULAPage.vue')
        },
        {
          path: 'invite',
          name: 'invite',
          component: () => import('@/views/LandingInvite.vue')
        },
        {
          path: 'privacypolicy',
          name: 'privacypolicyroute',
          component: () => import('@/views/PrivacyPolicyPage.vue')
        },
        {
          path: 'researcher',
          name: 'researcherroute',
          component: () => import('@/views/LandingResearcherPage.vue')
        },
        {
          path: 'sendinvite',
          name: 'sendinviteroute',
          component: () => import('@/views/InvitationPage.vue')
        },
        {
          path: 'search',
          name: 'searchroute',
          component: () => import('@/views/SearchPage.vue')
        },
        {
          path: 'subscribesolo',
          name: 'subscribesoloroute',
          component: () => import('@/views/SubscribeSoloPage.vue')
        },
        {
          path: 'subscriberesearcher',
          name: 'subscriberesearcherroute',
          component: () => import('@/views/SubscribeResearcherPage.vue')
        },
        {
          path: 'subscribeteam',
          name: 'subscribeteamroute',
          component: () => import('@/views/SubscribeTeamPage.vue')
        },
        {
          path: 'staytuned',
          name: 'staytunedroute',
          component: () => import('@/views/StayTunedPage.vue')
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/sections/Login.vue')
        },
        {
          path: 'logout',
          name: 'logout',
          component: () => import('@/views/sections/Logout.vue')
        },
        {
          path: 'register',
          redirect: '/'
        },
        {
          path: 'editprofile', // url: /path
          name: 'editprofileroute', // route name
          component: () => import('@/views/MyProfileEditPage.vue') // source code
        },
        {
          path: 'profile',
          name: 'profileroute',
          component: () => import('@/views/MyProfileReadOnlyPage.vue')
        },
        {
          path: 'profilereadonly',
          name: 'profilereadonlyroute',
          component: () => import('@/views/ProfileReadOnlyPage.vue')
        },
        {
          path: 'approveapplication',
          name: 'approveapplicationroute',
          component: () => import('@/views/ProfileApprovalPage.vue')
        },
        {
          path: 'features',
          name: 'features',
          component: () => import('@/views/FeaturesPage.vue')
        },

        {
          path: 'training',
          name: 'trainingroute',
          component: () => import('@/views/TrainingPage.vue')
        },
        {
          path: 'download',
          redirect: 'features'
        },
        {
          path: 'network',
          name: 'networkroute',
          component: () => import('@/views/NetworkPage.vue')
        },
        {
          path: 'approvals',
          name: 'approvalsroute',
          component: () => import('@/views/ApprovalsListPage.vue')
        }
      ]
    }
  ]
})

export default router
