import { AuthServiceInterface, AuthServiceConstants, RedirectCallback, StatusRoutes } from '@/msal/AuthServiceInterface'
import { AccountInfo, EndSessionRequest, EventMessage, EventType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import { authConfig, endSessionRequest, loginRequest, registrationRequest } from '@/config/authConfig'
import { b2cPolicies } from '@/config/policies'
import { EnvX, envx } from '@/environments/EnvX'
import { PubSub } from '@/publishsubscribe/pub-sub'

class AuthServiceMsal implements AuthServiceInterface {
  msalInstance: PublicClientApplication

  constructor() {
    this.msalInstance = new PublicClientApplication(authConfig)

    try {
      this.msalInstance.addEventCallback((event: EventMessage) => {
        // this.log(`🏗 constructor:addEventCallback event=${JSON.stringify(event, null, 4)}`)
        if (event.eventType === EventType.LOGIN_SUCCESS) {
          EnvX.log('🔐 (MSAL) 🏗 constructor: eventType=LOGIN_SUCCESS')
          PubSub.publish(AuthServiceConstants.LOG_STATUS_CHANGED, true)
        }
        if (event.eventType === EventType.LOGOUT_SUCCESS) {
          EnvX.log('🔐 (MSAL) 🏗 constructor: eventType=LOGOUT_SUCCESS')
          PubSub.publish(AuthServiceConstants.LOG_STATUS_CHANGED, false)
        }
      })
    } catch (exception) {
      EnvX.error(`🔐 (MSAL 💥) 🏗 constructor: exception=${JSON.stringify(exception, null, 4)}`)
    }
  }

  private getActiveAccount(): AccountInfo | null {
    let accountInfo: AccountInfo | null = null
    try {
      accountInfo = this.msalInstance.getActiveAccount()
    } catch (exception) {
      EnvX.error(`🔐 (MSAL 💥) 👷‍♀️ getActiveAccount: exception=${JSON.stringify(exception, null, 4)}`)
    }
    EnvX.log(`🔐 (MSAL) 👷‍♀️ getActiveAccount: ${accountInfo ? '👍' : '👎'}`)
    return accountInfo
  }

  async idTokenAsync(log: string): Promise<string | null> {
    let promise: string | null = null
    try {
      EnvX.log(`🔐 (${log}) → (MSAL) 🎫`)
      const accountInfo = this.getActiveAccount()
      if (accountInfo) {
        const idTokenClaims = accountInfo?.idTokenClaims ?? null
        if (idTokenClaims) {
          const claimsDictionary = idTokenClaims as {
            [key: string]: unknown
          }
          if (claimsDictionary) {
            const tfp = claimsDictionary.tfp
            if (tfp) {
              EnvX.log(`🔐 (${log}) • (MSAL) 🎫 idTokenAsync: tfp=${tfp}`)
              var request: RedirectRequest | null = null
              switch (tfp) {
                case b2cPolicies.names.login:
                  request = loginRequest
                  break
                case b2cPolicies.names.register:
                  request = registrationRequest
                  break
                default:
                  EnvX.warn(`🔐 (${log}) • (MSAL 🧨) 🎫 idTokenAsync: error unknown tfp=${tfp}`)
                  break
              }

              if (request) {
                const authenticationResult = await this.msalInstance.acquireTokenSilent(request)
                EnvX.log(`🔐 (${log}) • (MSAL) 🎫 idTokenAsync: authenticationResult=${authenticationResult ? '👍' : '👎'}`)
                if (authenticationResult) {
                  promise = authenticationResult.idToken
                }
              }
            }
          }
        }
      }
    } catch (exception) {
      EnvX.error(`🔐 (${log}) • (MSAL 💥) 🎫 idTokenAsync: exception=${JSON.stringify(exception, null, 4)}`)
    }
    if (!promise) {
      EnvX.log(`🔐 (${log}) • (MSAL) 🎫 EXCEPTION`)
      throw new Error(AuthServiceConstants.LOGGED_OUT)
    }
    EnvX.log(`🔐 (${log}) • (MSAL) 🎫 idTokenAsync:🍪${promise}🍪`)
    return promise
  }

  login(redirectCallback: RedirectCallback, statusRoutes: StatusRoutes): void {
    EnvX.log(`🔐 (MSAL) 🔑 login: redirectCallback=${redirectCallback}, statusRoutes=${JSON.stringify(statusRoutes, null, 4)}`)
    EnvX.assert(redirectCallback != null, '🔑 login redirectCallback=null')
    EnvX.assert(statusRoutes != null, '🔑 login statusRoutes=null')

    try {
      // handle auth redired/do all initial setup for msal
      this.msalInstance
        .handleRedirectPromise() // @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
        .then(async (authenticationResult) => {
          EnvX.log(`🔐 (MSAL) 🔑 login: authenticationResult=${authenticationResult ? '👍' : '👎'}`)
          if (authenticationResult) {
            this.msalInstance.setActiveAccount(authenticationResult.account)
            EnvX.log(`🔐 (MSAL) 🔑 login.then redirectCallback successRoute=${JSON.stringify(statusRoutes.successRoute, null, 4)}`)
            redirectCallback(statusRoutes.successRoute)
          } else {
            this.idTokenAsync('msal.login')
              .then(() => {
                EnvX.log(`🔐 (MSAL) 🔑 login: Already logged in, redirectCallback successRoute=${JSON.stringify(statusRoutes.successRoute, null, 4)}`)
                redirectCallback(statusRoutes.successRoute)
              })
              .catch(async (error) => {
                if (AuthServiceConstants.LOGGED_OUT !== error.message) {
                  EnvX.warn('🧨', error)
                }
                // Regardless of the error, attempt to login
                // redirect anonymous user to MSAL login page
                const request: RedirectRequest = loginRequest
                request.state = JSON.stringify(statusRoutes)

                // async and will navigate away
                EnvX.log(`🔐 (MSAL) 🔑 login: no token …leaving ${envx.webserverurl} → navigating away to MSAL`)
                await this.msalInstance.loginRedirect(request)
              })
          }
        })
        .catch((error) => {
          EnvX.warn(`🔐 (MSAL 🧨) 🔑 login: error=${error}, redirectCallback failureRoute=${JSON.stringify(statusRoutes.failureRoute, null, 4)}`)
          redirectCallback(statusRoutes.failureRoute)
        })
    } catch (exception) {
      EnvX.error(`🔐 (MSAL 💥) 🔑 login: exception=${JSON.stringify(exception, null, 4)}`)
    }
  }

  forceLogoutPopup(): void {
    try {
      this.msalInstance.logoutPopup()
    } catch (exception) {
      EnvX.error(`🔐 (MSAL 💥) 🚥 forceLogoutPopup: exception=${JSON.stringify(exception, null, 4)}`)
    }
  }

  logout(redirectCallback: RedirectCallback, statusRoutes: StatusRoutes): void {
    EnvX.log(`🔐 (MSAL) 🔓 logout redirectCallback=${redirectCallback}, statusRoutes=${JSON.stringify(statusRoutes, null, 4)}`)
    EnvX.assert(redirectCallback != null, '🔓 logout redirectCallback=null')
    EnvX.assert(statusRoutes != null, '🔓 logout statusRoutes=null')

    try {
      const activeAccount = this.msalInstance.getActiveAccount()
      EnvX.log(`🔐 (MSAL) 🔓 logout: activeAccount=${activeAccount ? '👍' : '👎'}`)
      // async and will navigate away. We can't pass statusRoutes to the request
      const request: EndSessionRequest = endSessionRequest
      request.account = activeAccount
      EnvX.log(`🔐 (MSAL) 🔓 logout: …leaving ${envx.webserverurl} → navigating away to MSAL`)
      this.msalInstance.logoutRedirect(request)
    } catch (exception) {
      EnvX.error(`🔐 (MSAL 💥) 🔓 logout exception=${JSON.stringify(exception, null, 4)}`)
    }
  }

  register(redirectCallback: RedirectCallback, statusRoutes: StatusRoutes): void {
    EnvX.log(`🔐 (MSAL) 📒 register redirectCallback=${redirectCallback}, statusRoutes=${JSON.stringify(statusRoutes, null, 4)}`)
    EnvX.assert(redirectCallback != null, '📒 register redirectCallback=null')
    EnvX.assert(statusRoutes != null, '📒 register statusRoutes=null')

    try {
      // handle auth redired/do all initial setup for msal
      this.msalInstance
        .handleRedirectPromise()
        .then(async (authenticationResult) => {
          EnvX.log(`🔐 (MSAL) 📒 register: authenticationResult=${authenticationResult ? '👍' : '👎'}`)
          if (authenticationResult) {
            // Already logged-in, can't register
            this.msalInstance.setActiveAccount(authenticationResult.account)
            EnvX.log(`🔐 (MSAL) 📒 register: redirectCallback failureRoute=${JSON.stringify(statusRoutes.failureRoute, null, 4)}`)
            redirectCallback(statusRoutes.failureRoute)
          } else {
            const activeAccount = this.msalInstance.getActiveAccount()
            EnvX.log(`🔐 (MSAL) 📒 register: activeAccount=${activeAccount ? '👍' : '👎'}`)
            if (activeAccount) {
              // Already logged-in, can't register
              EnvX.log(`🔐 (MSAL) 📒 register: Already logged in failureRoute=${JSON.stringify(statusRoutes.failureRoute, null, 4)}`)
              redirectCallback(statusRoutes.failureRoute)
            } else {
              // redirect anonymous user to MSAL register page
              const request: RedirectRequest = registrationRequest
              request.state = JSON.stringify(statusRoutes)

              // async and will navigate away
              EnvX.log(`🔐 (MSAL) 📒 register:  …leaving ${envx.webserverurl} → navigating away to MSAL`)
              await this.msalInstance.loginRedirect(request)
            }
          }
        })
        .catch((error) => {
          EnvX.warn(`🔐 (MSAL 🧨) 📒 register error=${error}, redirectCallback failureRoute=${JSON.stringify(statusRoutes.failureRoute, null, 4)}`)
          redirectCallback(statusRoutes.failureRoute)
        })
    } catch (exception) {
      EnvX.error(`🔐 (MSAL 💥) 📒 register exception=${JSON.stringify(exception, null, 4)}`)
    }
  }

  // Must be invoked on every page load
  // It returns a promise that _may_ contain a redirectRoute.
  // If so, it is the responsibility of the caller to navigate to that route
  async flushRedirectPromisesAsync(): Promise<string | null> {
    var returnRoute: string | null = null

    // Resolve msal.handleRedirectPromise
    // When doing so, we may find a redirectRoute in the authenticationResult
    // Routes are passed
    const authenticationResult = await this.msalInstance.handleRedirectPromise()
    EnvX.log(`🔐 (MSAL) 🔄 flushRedirectPromisesAsync: authenticationResult=${authenticationResult ? '👍' : '👎'}`)
    if (authenticationResult) {
      this.msalInstance.setActiveAccount(authenticationResult.account)
      EnvX.log(`🔐 (MSAL) 🔄 flushRedirectPromisesAsync: state=${authenticationResult.state}`)
      if (authenticationResult.state) {
        // the statusRoutes were stashed in the `state` in the `request`
        const statusRoutes = JSON.parse(authenticationResult.state) as StatusRoutes
        returnRoute = statusRoutes.successRoute
      }
    }

    EnvX.log(`🔐 (MSAL) 🔄 flushRedirectPromisesAsync return statusRoute=${JSON.stringify(returnRoute, null, 4)}`)
    return returnRoute
  }
}

export { AuthServiceMsal as AuthService }
