import Vue from 'vue'
import App from './App.vue'
import { AuthService } from '@/msal/AuthServiceMsal'
import router from './router'
import vuetify from '@/plugins/vuetify'
import './plugins'

Vue.prototype.$AuthService = new AuthService()
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
