<script>
export default {
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentProfileReadOnlySection: () => import('./sections/ProfileReadOnlySection.vue'),
    componentHistoryBack: () => import('./sections/ComponentHistoryBack.vue')
  },
  data: () => ({
    titleprefix: ''
  })
}
</script>

<template>
  <div>
    <componentHeader />
    <componentProfileReadOnlySection :titlePrefixProp="titleprefix" />
    <componentHistoryBack />
  </div>
</template>
