import Vue from 'vue'

export default Vue.extend({
  name: 'Heading',

  inject: {
    heading: {
      default: () => ({ align: 'left' })
    }
  },

  provide () {
    return {
      heading: {
        align: this.$data.align
      }
    }
  },

  computed: {
    justify () {
      switch (this.$data.align) {
        case 'center':
          return 'center'
        case 'right':
          return 'end'
        default:
          return 'start'
      }
    }
  }
})
