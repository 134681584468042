enum RegistrationStage {
  Disabled = 0, // same as Unknown
  Empty = 1, // use for showRedeemFormProp()
  NotSubmitted, // same as InProgress
  Submitted,
  Rejected,
  Accepted,
  Completed // Complete, same thing.
}

enum AlertTimeout {
  null = 0,
  short = 2000,
  medium = 10000,
  long = 20000,
  infinity = -1
}

enum ItemsPerTable {
  all = -1,
  five = 5
}

export { RegistrationStage, AlertTimeout, ItemsPerTable }
