/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */
import { b2cPolicies, secretConfig } from './policies'
import { Configuration, EndSessionRequest, LogLevel, RedirectRequest } from '@azure/msal-browser'
import { EnvX, envx } from '@/environments/EnvX'

// redirectUri: 'https://proinsight-dev.azurewebsites.net/profile'
// postLogoutRedirectUri: 'https://proinsight-dev.azurewebsites.net'

const msalConfig: Configuration = {
  auth: {
    clientId: secretConfig.clientId, // Client ID of your app registered with our Application registration portal : https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredAppsPreview in Microsoft Identity Platform
    authority: b2cPolicies.authorities.login.authority, // You can configure a specific authority, defaults to " " or "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // An array of URIs that are known to be valid. Used in B2C scenarios.
    // cloudDiscoveryMetadata - A string containing the cloud discovery response. Used in AAD scenarios.
    redirectUri: envx.webserverurl + '/profile', // The redirect URI where authentication responses can be received by your application. It must exactly match one of the redirect URIs registered in the Azure portal.
    postLogoutRedirectUri: envx.webserverurl, // The redirect URI where the window navigates after a successful logout.
    navigateToLoginRequestUrl: false // Boolean indicating whether to navigate to the original request URL after the auth server navigates to the redirect URL
    // clientCapabilities - Array of capabilities which will be added to the claims.access_token.xms_cc request property on every network request.
    // protocolMode - Enum that represents the protocol that msal follows. Used for configuring proper endpoints.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          // EnvX.log('Pii ☢️ ' + message)
          return
        }
        switch (level) {
          case LogLevel.Error:
            EnvX.error('🛑 ' + message)
            return
          case LogLevel.Info:
            EnvX.log('👆 ' + message)
            return
          case LogLevel.Verbose:
            EnvX.log('💬 ' + message)
            return
          case LogLevel.Warning:
            EnvX.warn('⚠️' + message)
        }
      }
    }
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest: RedirectRequest = {
  scopes: [],
  authority: b2cPolicies.authorities.login.authority,
  redirectUri: envx.webserverurl + '/login' // redirect login to login for further processing
}

const endSessionRequest: EndSessionRequest = {
  authority: b2cPolicies.authorities.login.authority,
  postLogoutRedirectUri: envx.webserverurl // redirect logout to /
}

const registrationRequest: RedirectRequest = {
  scopes: ['openid'],
  authority: b2cPolicies.authorities.register.authority,
  redirectUri: envx.webserverurl + '/login' // redirect register to login for further processing
}

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest: RedirectRequest = {
  scopes: []
}

export { msalConfig as authConfig }
export { loginRequest, endSessionRequest, registrationRequest, tokenRequest }
