<script>
import View from '@/views/View'

export default {
  name: 'StayTunedPage',
  metaInfo: { title: 'Stay Tuned' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentStayTunedSection: () => import('./sections/StayTunedSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentStayTunedSection />
  </div>
</template>
