<script>
import View from '@/views/View'

export default {
  name: 'SubscribeResearcherPage',
  metaInfo: { title: 'SubscribeResearcher' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue'),
    componentSubscribeResearcherSection: () => import('./sections/SubscribeResearcherSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentSubscribeResearcherSection />
  </div>
</template>
