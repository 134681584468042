<script>
import View from '@/views/View'

export default {
  name: 'SubscribeSoloPage',
  metaInfo: { title: 'Subscribe' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue'),
    componentSubscribeSoloSection: () => import('./sections/SubscribeSoloSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentSubscribeSoloSection />
  </div>
</template>
