<script>
import View from '@/views/View'

export default {
  name: 'ApprovalsListPage',
  metaInfo: { title: 'Approvals' },
  extends: View,
  components: {
    componentApprovalsListSection: () => import('./sections/ApprovalsListSection.vue'),
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentApprovalsListSection />
  </div>
</template>
