<script>
import View from '@/views/View'

export default {
  name: 'SubscribeTeamPage',
  metaInfo: { title: 'SubscribeTeam' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue'),
    componentSubscribeTeamSection: () => import('./sections/SubscribeTeamSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentSubscribeTeamSection />
  </div>
</template>
