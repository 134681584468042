<script>
import View from '@/views/View'

export default {
  name: 'researcher',
  metaInfo: { title: 'Researcher' },
  extends: View,
  components: {
    componentFooter: () => import('./sections/Footer.vue'),
    componentHeader: () => import('./sections/Header.vue'),
    componentResearcherSection: () => import('./sections/ResearcherSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentResearcherSection />
    <componentFooter />
  </div>
</template>
