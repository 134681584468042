// westus
// Will overwrite EnvX.ts on westus production server
const deployment = 'westus'

const envx = {
  deployment: deployment,

  // Web app
  userfacing: true,
  webservername: deployment,
  webservericon: '',
  webserverurl: `https://proinsight-${deployment}.azurewebsites.net`,

  // Function Apps (public)
  publicFunctionsUrl: `https://no-auth-${deployment}.azurewebsites.net/api`,

  // Function Apps (token auth)
  tokenAuthFunctionsUrl: `https://b2c-token-auth-${deployment}.azurewebsites.net/api`
}

class EnvX {
  public static assert(_condition?: boolean | undefined, ..._data: any[]): void {}

  public static error(..._data: any[]): void {}

  public static log(..._data: any[]): void {}

  public static warn(..._data: any[]): void {}
}

export { EnvX, envx }
