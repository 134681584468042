<template>
  <v-row justify="center">
    <v-progress-circular indeterminate color="primary" />
    <div style="min-height: 4px">
      <v-progress-linear v-model="value" :active="show" :indeterminate="query" :query="true" />
    </div>
  </v-row>
</template>

<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { EnvX } from '@/environments/EnvX.ts'

export default {
  name: 'LoginSection',
  data: function () {
    return {
      value: 0,
      query: false,
      show: true,
      interval: 0
    }
  },

  mounted() {
    // Invoke this Vue using $router.push({name:params:})
    // pass a StatusRoutes object as parameter
    this.queryAndIndeterminate() // animation
    setTimeout(this.loginAction, this.nullTimeoutProp)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    }
  },

  methods: {
    // Redirection **must** be done by Vue. AuthService is not a navigation service
    redirectToRoute(redirectRoute) {
      EnvX.assert(redirectRoute != null, '⧄ redirectRoute')
      if (redirectRoute) {
        EnvX.log('Redirecting to route «' + redirectRoute + '»')
        this.$router.push({
          name: redirectRoute,
          params: {}
        })
      }
    },

    loginAction() {
      // We didn't get routes because we weren't logged in. Find them in params
      const paramsRoutes = this.$route.params
      EnvX.assert(paramsRoutes.successRoute != null, '⧄ successRoute')
      EnvX.assert(paramsRoutes.failureRoute != null, '⧄ failureRoute')
      EnvX.log(`⧈ …statusRoutes passed as Vue route params: successRoute=«${paramsRoutes.successRoute}», failureRoute=«${paramsRoutes.failureRoute}»`)

      // If routes are missing (direct navigation?) supply some default
      const statusRoutes = {
        successRoute: paramsRoutes.successRoute ?? 'homeroute',
        failureRoute: paramsRoutes.failureRoute ?? 'homeroute'
      }

      this.$AuthService // flush pending logins, or login
        .flushRedirectPromisesAsync()
        .then((redirectRoute) => {
          EnvX.log('⧈ Resolve MSAL promises before attempting to login again')

          if (redirectRoute) {
            // If we get a redirectRoute from the auth service, go there
            EnvX.log('⧈ …redirectRoute stashed in the state «' + redirectRoute + '»')
            this.redirectToRoute(redirectRoute)
          } else {
            this.$AuthService.login(this.redirectToRoute, statusRoutes)
          }
        })
        .catch(() => {
          const loggedOutRoute = {
            successRoute: 'homeroute',
            failureRoute: 'homeroute'
          }
          this.$AuthService.logout(this.redirectToRoute, loggedOutRoute)
          this.$AuthService.login(this.redirectToRoute, statusRoutes)
        })
    },

    queryAndIndeterminate() {
      this.query = true
      this.show = true
      this.value = 0

      setTimeout(() => {
        this.query = false

        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.show = false
            return setTimeout(this.queryAndIndeterminate, 2000)
          }
          this.value += 25
        }, 1000)
      }, 2500)
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
  position: absolute;
  bottom: 45%;
}
</style>
