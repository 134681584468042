<template>
  <v-fade-transition mode="out-in">
    <router-view></router-view>
  </v-fade-transition>
</template>

<script>
import { envx } from '@/environments/EnvX.ts'
export default {
  name: 'ProInsight',
  metaInfo: {
    title: 'ProInsight',
    titleTemplate: '%s - ProInsight',
    htmlAttrs: { lang: 'en' },
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }]
  },
  mounted() {
    if (envx.userfacing) {
      console.assert = function () {}
      console.debug = function () {}
      console.error = function () {}
      console.info = function () {}
      console.log = function () {}
      console.warn = function () {}
    }
  }
}
</script>

<style lang="sass">
.extra-padding
  padding-bottom: 96px !important
  padding-top: 96px !important

  @media screen and (max-width: 959px)
    padding-top: 48px !important
    padding-bottom: 48px !important
</style>
