<script>
import View from '@/views/View'

export default {
  name: 'MyProfileEditPage',
  metaInfo: { title: 'Edit your profile' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue'),
    componentMyProfileEditSection: () => import('./sections/MyProfileEditSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentMyProfileEditSection />
  </div>
</template>
