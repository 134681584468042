<script>
import View from '@/views/View'

export default {
  name: 'InvitationPage',
  metaInfo: { title: 'Invitation' },
  extends: View,
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentInvitationSection: () => import('./sections/InvitationSection.vue'),
    componentMustLoginSection: () => import('./sections/MustLoginSection.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentMustLoginSection />
    <componentInvitationSection />
  </div>
</template>
