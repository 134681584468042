<script>
import View from '@/views/View'

export default {
  name: 'PayingFeaturesPage',
  metaInfo: { title: 'Welcome' },
  extends: View,
  components: {
    componentFeaturesSection: () => import('./sections/FeaturesSection.vue')
  }
}
</script>

<template>
  <div>
    <componentFeaturesSection />
  </div>
</template>
