<script>
import View from '@/views/View'

export default {
  name: 'home',
  metaInfo: { title: 'Home' },
  extends: View,
  components: {
    componentFooter: () => import('./sections/Footer.vue'),
    componentHeader: () => import('./sections/Header.vue'),
    componentWelcome: () => import('./sections/Welcome.vue')
  },
  props: {
    id: {
      type: String,
      default: 'home'
    }
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentWelcome />
    <componentFooter />
  </div>
</template>
