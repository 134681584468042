type RedirectCallback = (redirectRoute: string) => void
type StatusRoutes = { successRoute: string; failureRoute: string }

enum AuthServiceConstants {
  LOG_STATUS_CHANGED = 'authServiceConstants:LOG_STATUS_CHANGED',
  LOGGED_OUT = 'authServiceConstants:LOGGED_OUT'
}

enum AuthServiceStage {
  LoggedOut = -1,
  Unknown = 0,
  LoggedIn
}

interface AuthServiceInterface {
  flushRedirectPromisesAsync(cancelRoute: string): Promise<string | null>
  forceLogoutPopup(): void
  idTokenAsync(log: string): Promise<string | null>
  login(redirectCallback: RedirectCallback, statusRoutes: StatusRoutes): void
  logout(redirectCallback: RedirectCallback, statusRoutes: StatusRoutes): void
  register(redirectCallback: RedirectCallback, statusRoutes: StatusRoutes): void
}

export { AuthServiceInterface, AuthServiceConstants, AuthServiceStage, RedirectCallback, StatusRoutes }
