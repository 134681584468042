<script>
export default {
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentProfileReadOnlySection: () => import('./sections/ProfileReadOnlySection.vue'),
    componentProfileApprovalFooter: () => import('./sections/ProfileApprovalFooter.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentProfileReadOnlySection titlePrefixProp="Approval for" />
    <componentProfileApprovalFooter />
  </div>
</template>
