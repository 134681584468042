<template>
  <v-app>
    <home-view />
    <!-- <home-settings /> -->
  </v-app>
</template>

<script>
export default {
  name: 'HomeLayout',

  components: {
    HomeView: () => import('@/layouts/home/BaseView')
  }
}
</script>
