<script>
export default {
  components: {
    componentHeader: () => import('./sections/Header.vue'),
    componentProfileReadOnlySection: () => import('./sections/ProfileReadOnlySection.vue'),
    componentProfileFooterEdit: () => import('./sections/ComponentProfileFooterEdit.vue')
  }
}
</script>

<template>
  <div>
    <componentHeader />
    <componentProfileReadOnlySection titlePrefixProp="" myselfProp="true" />
    <componentProfileFooterEdit />
  </div>
</template>
