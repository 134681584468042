<template>
  <v-row justify="center">
    <v-progress-circular indeterminate color="primary" />
    <div style="min-height: 4px">
      <v-progress-linear v-model="value" :active="show" :indeterminate="query" :query="true" />
    </div>
  </v-row>
</template>

<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { EnvX } from '@/environments/EnvX.ts'

export default {
  name: 'LogoutSection',
  data: function () {
    return {
      value: 0,
      query: false,
      show: true,
      interval: 0
    }
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    }
  },

  mounted() {
    // Invoke this Vue using $router.push({name:params:})
    // pass a StatusRoutes object as parameter
    this.queryAndIndeterminate() // animation
    setTimeout(this.logoutAction, this.nullTimeoutProp)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    // Redirection **must** be done by Vue. AuthService is not a navigation service
    redirectAction(redirectRoute) {
      EnvX.assert(redirectRoute != null, '⊗ redirectRoute')
      if (redirectRoute) {
        EnvX.log('⊙ Logout redirecting to route «' + redirectRoute + '»')
        this.$router.push({
          name: redirectRoute,
          params: {}
        })
      }
    },

    logoutAction() {
      const paramsRoutes = this.$route.params
      EnvX.assert(paramsRoutes.successRoute != null, '⊗ successRoute')
      EnvX.log('⊙ …statusRoutes passed as Vue route params: «' + paramsRoutes.successRoute + '»')
      // If route is missing (direct navigation?) supply some default
      const statusRoutes = {
        successRoute: paramsRoutes.failureRoute ?? 'homeroute'
      }

      this.$AuthService // flush pending promises and logout
        .flushRedirectPromisesAsync()
        .then((redirectRoute) => {
          EnvX.log('⊙ Resolved MSAL promises before attempting to logout again')
          if (redirectRoute) {
            EnvX.warn('⊗ …redirectRoute stashed in the state, we had a leftover login «' + redirectRoute + '»')
          }
          this.$AuthService.logout(this.redirectAction, statusRoutes)
        })
        .catch(this.$AuthService.logout(this.redirectAction, statusRoutes))
    },

    queryAndIndeterminate() {
      this.query = true
      this.show = true
      this.value = 0

      setTimeout(() => {
        this.query = false

        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.show = false
            return setTimeout(this.queryAndIndeterminate, 2000)
          }
          this.value += 25
        }, 1000)
      }, 2500)
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
  position: absolute;
  bottom: 45%;
}
</style>
