<script>
import pdf from 'vue-pdf'
import View from '@/views/View'

export default {
  name: 'EULAPage',
  metaInfo: { title: 'End User Licence Agreement' },
  extends: View,
  components: {
    pdf,
    componentFooter: () => import('./sections/Footer.vue')
  }
}
</script>

<template>
  <div>
    <pdf src="./eula.pdf"></pdf>
    <componentFooter />
  </div>
</template>
