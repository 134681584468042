// westus
import { envx } from '@/environments/EnvX'

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const environment = envx.deployment

const prosinsightb2c = `proinsightb2c${environment}`
const authorityDomain = `${prosinsightb2c}.b2clogin.com`
const authorityUrl = `https://${authorityDomain}/${prosinsightb2c}.onmicrosoft.com`
const policiesNames = {
  register: 'B2C_1_REGISTER_FEDERATED',
  login: 'B2C_1_SIGN_IN_FEDERATED',
  reset: 'B2C_1_PASSWORD_RESET'
}
const b2cPolicies = {
  names: policiesNames,
  authorities: {
    register: {
      authority: `${authorityUrl}/${policiesNames.register}`
    },
    login: {
      authority: `${authorityUrl}/${policiesNames.login}`
    },
    reset: {
      authority: `${authorityUrl}/${policiesNames.reset}`
    }
  },
  authorityDomain: authorityDomain
}

const secrets = {
  // b2c tenant vue-and-mobile-b2c Application (client) ID
  // https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/7dcfc0f8-e957-4b22-8751-18059658dc63/isMSAApp~/false
  clientId: '7dcfc0f8-e957-4b22-8751-18059658dc63'
}

export { b2cPolicies, secrets as secretConfig }
